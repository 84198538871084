body {
  background: #434343 !important;
}

.txt-desc {
  color: #eee !important;
  font-weight: 600 !important;
}

.title-txt {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 8px !important;
  margin: 0 !important;
  margin-left: 8px !important;
  /* width: 100% !important; */
  font-family: 'Bungee', cursive !important;
  color: #707070 !important;
  
  /* font-size: 3 !important; */
  font-size: 3.2vw !important;
}
.title-content {
  padding: 8px 8px 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 16px;
  /* border: 1px solid; */
}
.container-qrcodeGen {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  margin: 8px;
}
.btn-edit.btn-how-to-use {
  display: flex !important;
  flex-wrap: nowrap !important;

  flex-direction: row-reverse !important;
  justify-content: flex-end !important;
  width: 220px !important;
  border: none !important;
  background-color: none !important;
}
.btn-edit.btn-how-to-use > span {
  text-align: end !important;
  width: fit-content !important;
}
.btn-edit.btn-how-to-use > span.icon-adjust {
  width: 18% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.icon-youtube {
  margin-left: 8px !important;
}
.btn-edit > span.anticon.icon-youtube svg {
  width: 40px !important;
  height: 40px !important;
  align-self: flex-start !important;
}
.btns-wrapper {
  background: #666;
  --size_spacing: 16px;
  padding: 16px 24px 24px 16px;
  border-radius: 16px !important;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  border-radius: 16px;
  /* border: 1px solid; */
}
.btn-edit:focus {
  color: #FFCC00 !important;
  background: #007aff !important;
}
.btn-edit:hover:disabled {
  color: #aaa !important;
  background: #999 !important;
  border-color: #666 !important;
}
.btn-edit:hover {
  color: #FFCC00 !important;
  background: #0067ff !important;
  border-color: #0067ff !important;
}
.btn-edit {
  background: #eee !important;
  /* margin: 0 8px 0 8px; */
  height: 56px !important;
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  flex-direction: row !important;
  flex-flow: wrap;
  flex-wrap: wrap !important;
  white-space: normal !important;
  text-align: end !important;
  padding: 8px !important;
  border-radius: 8px !important;
  font-weight: 700 !important;
}

.btn-edit > span {
  width: 50% !important;
  line-height: 1.4 !important;
  margin-right: 8px;
  text-align: center !important;
  display: block !important;
  font-size: 11px !important;
}
.btn-edit > span.anticon {
  width: 18% !important;
}
.btn-edit > span.anticon svg {
  --size: 20px;
  width: var(--size) !important;
  height: var(--size) !important;
}
.btns-wrapper > .ant-btn > .anticon + span, .ant-btn > span + .anticon {
  margin-left: 0 !important;
}
.options-wrapper {
  position: relative;
  gap: 16px;
  /* background: #666; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: space-between; */
  /* border: solid 1px #0067ff; */
  /* border-radius: 16px; */
  /* border: 1px solid; */
  /* padding: 8px; */
}
.wrpper-opt-2 {
  position: relative;
  border-radius: 16px;
  background: #666;
  padding: 8px;
  height: 200px;
}
.wrpper-opt-1 {
  position: relative;
  border-radius: 16px;
  background: #666;
  padding: 8px;
  /* height: 3px; */
}
.qrCodeExample {
  width: 16vw;
}
.txtAreaOpt {
  margin: 4px 0 4px 0 !important;
  resize: none;
  font-size: 12px !important;
}
.typography-container {
  width: 100% !important;
  padding: 8px;
  margin: 0 !important;
}
.btns-container-opts {
  display: flex;
  gap: 8px;
}
[href]:hover {
  background: #3b89ff !important;
}
[href] {
  border: none !important;
  transition: all .2s ease !important;
  color: #eee !important;
  background: #0067ff !important;
}
.margins {
  margin: 4px 0 4px 0 !important;
  font-size: 11.4px !important;
  white-space: normal !important;
}
.btns-down-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.qrcode-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 16px;
  height: 100%;
  /* padding: 8px 32px 180px 32px; */
  /* width: fit-content; */
}
.result-wrapper {
  
  background: #666;
  border-radius: 16px;
  /* border: 1px solid; */
}
[data-icon="loading"] {
  width: 64px;
  height: 64px;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.input-container > input {
  width: 40% !important;
}
.tab-wrapper {
  margin: 8px;
}
.frame-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}
.qrcodeImg-tab {
  --size: 80px;
  width: var(--size);
  height: var(--size);
}
.frame-opt {
  padding: 6px;
}
.frame-none {
  opacity: .8;
  cursor: pointer;
  position: relative;
}
.frame-none::before {
  position: absolute;
  content: 'X';
  /* transform-origin: 50% 50%; */
  font-size: xx-large;
  font-weight: 900;
  color: red;
  top: 20%;
  left: 35%;
}
.frame-1 {
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  position: relative;
}
.frame-1::before {
  border: 4px solid;
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.frame-1::after {
  content: 'capture';
  border-radius: 16px;
  font-size: small;
  position: absolute;
  bottom: -6%;
  background: #fff;
  line-height: 1;
  margin: 0;
  font-weight: 900;
  padding: 0 8px 0 8px;
} 
.frame-2 {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding: 10px;
}
.frame-2::before {
  background: #161616;
  z-index: -3;
  position: absolute;
  top: 0;
  content: '';
  width: 100%;
  height: 115%;
  border-radius: 16px;
}
.frame-2::after {
  content: 'capture';
  border-radius: 16px;
  font-size: small;
  position: absolute;
  bottom: -6%;
  color: #fff;
  line-height: 1;
  margin: 0;
  font-weight: 900;
  padding: 0 8px 0 8px;
} 
.frame-3 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  height: fit-content !important;
}
.frame-3::after {
  content: 'use sua camêra';
  /* border-radius: 16px; */
  font-size: 12px;
  position: absolute;
  bottom: -28%;
  text-align: center;
  padding: 4px !important;
  color: #fff;
  background-color: #161616;
  line-height: 1;
  margin: 0;
  font-weight: 300;
  width: 72px;
  padding: 0 8px 0 8px;
  
} 
.frame-4 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
}
.frame-4::after {
  content: 'capture o código';
  /* border-radius: 16px; */
  font-size: 12px;
  position: absolute;
  bottom: -28%;
  text-align: center;
  padding: 4px !important;
  color: #fff;
  background-color: #161616;
  line-height: 1;
  margin: 0;
  font-weight: 300;
  width: 72px;
  padding: 0 8px 0 8px;
} 
.frame-5 {
  align-self: center;
  justify-self: center;
  padding-top: 40px;
}
.tabpane {
  height: 260px !important;
  /* display: flex !important; */
}
.txt-colorshape {
  text-align: center !important;
  white-space: normal !important;
  padding: 4px;
}
.ColorShape-1 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transform: translateX(32px);
}
.ColorShape-container {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}
.ColorShape-2 {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  width: 230px;
  justify-content: center;
  align-items: center;
  padding: 24px;

}
.shape-1::before {
  position: absolute;
  content: 'X';
  
  font-size: xx-large;
  line-height: .6;
  font-weight: 900;
  color: red;
  top: 32%;
  left: 32%;
}
.shape-1 {
  position: relative;
  margin: 8px;
  width: 64px;
  height: 64px;
  border: #161616 solid 1px;
}
.shape-2 {
  margin: 8px;
  padding: 8px;
  width: 64px;
  height: 64px;
  border: #161616 solid 1px;
}
.shape-3 {
  margin: 8px;
  padding: 8px;
  width: 64px;
  height: 64px;
  border: #161616 solid 1px;
}
.qrcode-img-tab {
  width: 100%;
  height: 100%;
}
.logo-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}
.logo-1::before {
  position: absolute;
  content: 'X';
  
  font-size: xx-large;
  line-height: .6;
  font-weight: 900;
  color: red;
  top: 32%;
  left: 32%;
  
  z-index: 100;
}
.logo-1:hover {
  border: #FFCC00 solid 2px;
}
.logo-1 {
  transition: all .2s ease;
  position: relative;
  /* margin: 8px; */
  width: 64px;
  height: 64px;
  /* margin-top: 32px; */
  border: #161616 solid 2px;
  cursor: pointer;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  z-index: 98;
}
.logo-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.img-logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.img-logo::before {
  content: '';
  font-size: small;
  background: #fff;
  padding: 2px;
  /* top: 32%; */
  position: absolute;
}
.logo-img-tab {
  width: 56px;
  height: 56px;
}
.btns-container {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 8px;
}
.colorft > span {
  color: #eee !important;
}
.colorft {
  color: #eee !important;
}
.finaldesctxt {
  color: #aaa !important;
  font-size: 12px !important;
  line-height: 13px !important;
}
.btns-down {
  /* width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px; */
  width: 100% !important;
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-columns: 3fr 1fr; */
  gap: 8px;
}
.border-btns-mark {
  border: 4px solid #0067ff !important;
}
/* #qrcode-container { */
  /* background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOgAAADoCAYAAADlqah4AAAEEUlEQVR4nO3az+rlcxzH8ddvJpPYzdJMKcoFUErmImZkuARrkmtQbChXMQorG3EDpCQlFGWugIUZi9GpM2Ey+aUx81w8HvWp01mc77fvu+f58/mek/2zc9suH9dz2x47PgfcG79u+3nbl9s+2PbRtpuneeUr277bdsuyrPu2Ds29cGeMZ+94/Oa2d7ed9y4J99WhuZe3PbLts+Mbw98CPcT5hpnAA/X8toe3fXI4idu/QV/cds1cIOPqtvdPjps/P2y7aDaQcX3bE2eOpYoTWi5se+nM8VYK0HP58BX3x22PGw7k/HQ4od9Oca/nktnBPXXpFN3dOLl9v+Vf3O0fR8B/96/tnXFxoUugECZQCBMohAkUwgQKYQKFMIFCmEAhTKAQJlAIEyiECRTCBAphAoUwgUKYQCFMoBAmUAgTKIQJFMIECmEChTCBQphAIUygECZQCBMohAkUwgQKYQKFMIFCmEAhTKAQJlAIEyiECRTCBAphAoUwgUKYQCFMoBAmUAgTKIQJFMIECmEChTCBQphAIUygECZQCBMohAkUwgQKYQKFMIFCmEAhTKAQJlAIEyiECRTCBAphAoUwgUKYQCFMoBAmUAgTKIQJFMIECmEChTCBQphAIUygECZQCBMohAkUwgQKYQKFMIFCmEAhTKAQJlAIEyiECRTCBAphAoUwgUKYQCFMoBAmUAgTKIQJFMIECmEChTCBQphAIUygECZQCBMohAkUwgQKYQKFMIFCmEAhTKAQJlAIEyiECRTCBAphAoUwgUKYQCFMoBAmUAgTKIQJFMIECmEChTCBQphAIUygECZQCBMohAkUwgQKYQKFMIFCmEAhTKAQJlAIEyiECRTCBAphAoUwgUKYQCFMoBAmUAgTKIQJFMIECmEChTCBQphAIUygECZQCBMohAkUwgQKYQKFMIFCmEAhTKAQJlAIEyiECRTCBAphAoUwgUKYQCFMoBAmUAgTKIQJFMIECmEChTCBQphAIUygECZQCBMohAkUwgQKYQKFMIFCmEAhTKAQJlAIEyiECRTCBAphAoUwgUKYQCFMoBAmUAgTKIQJFMIECmEChTCBQphAIUygECZQCBMohAkUwgQKYQKFMIFCmEAhTKAQJlAIEyiECRTCBAphAoUwgUKYQCFMoBAmUAg7BPrLKU7vkiHCPfX8KV7s5sm2b7c95dpDzk+HT9CvzAWSPj8E+qHZQNKHh6+457b9sO2iGUHG9W1PHj5Bb257zVwg5dVtN84ez+ibbY+ecmcJ+H+9te2dwxHO/uUwn247v+1ZFx8emPe2vb7t1u4I9PDEx9u+3vb0MVbg/vh+2yvb3r4d58HJXQ592Di6uu3Ktme2Xdj2kEHBPfP7cSPoi20fbLt23A/607Y/AJlBI3TjGspeAAAAAElFTkSuQmCC'); */
  /* background-size: contain; */
  /* background-repeat: no-repeat; */
  /* background-position: 0% 0%; */
/* } */
.btns-final:disabled {
  color: #666 !important;
  opacity: .2 !important;
  /* background: #eee !important; */
}
.btns-final > span {
  width: 100% !important;
}
.btns-final {
  background: #FFCC00 !important;
  color: #161616 !important;
  /* border: 1px solid #161616 !important; */
  border: none !important;
  font-weight: 700 !important;
  width: 100% !important;
}
.btns-final-copy:disabled {
  color: #666 !important;
  opacity: .2 !important;
}
.btns-final-copy {
  background: #FEE47B !important;
  border: none !important;
}
.ant-form-item-explain-sucess {
  color: #eee !important;
  display: none !important;
}
.ant-form-item-explain-error {
  color: #ffdfde !important;
}

.qrcodegen-container {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 224px;
  overflow: hidden;
  height: 224px;
  border-radius: 16px;
}
.btn-copy {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.mark {
  font-size: 11px !important;
  color: #aaa !important;
  margin-left: 16px !important;
  font-weight: 900 !important;
}

.shorter-container-total {
  width: 100%;
}
.result-container {
  padding: 0 16px 0 16px;
  width: 100%;
}
.short-box {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.short-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  /* height: 80px; */
  /* max-width: 680px; */
  /* margin: 0 8px 0 8px; */
  padding: 0 16px 8px 16px;
  margin-bottom: 24px;
}
.inpt-short {
  flex: 3;
  height: 40px !important;
}
.btn-short {
  flex: 1;
  margin-left: 8px;
  display: flex !important;
  flex-direction: row !important;
  width: 100%;
  height: 40px !important;
  border: none !important;
  font-weight: 700 !important;
  width: 100% !important;
  background: #FFCC00 !important;
}

.txt-short {
  margin: 8px 0 0 8px !important;
}
.inpt-short-read::placeholder {
  color: #FFCC00 !important;
}
.inpt-short-read {
  opacity: .8 !important;
  height: 40px !important;
  width: 64% !important;
  background-color: #666 !important;
  flex: 6 !important;
  color: #ffffff !important;
}
.ant-form-item {
  margin-bottom: 0 !important;
  flex: 3;
}
.iconizinho {
  width: 32px !important;
}
.result-container {
  display: flex;
  flex-direction: row;
}
.img-crop-modal {
  /* width: 400px;
  height: 600px; */
  width: 100%;
  height: 100%;
}
[aria-selected="true"].ant-tabs-tab-btn {
  color: #eee !important;
}
.ant-tabs-tab-btn {
  color:#aaa !important ;
}
.zero-padding:disabled {
  opacity: .4 !important;
}
.zero-padding {
  padding: 8px !important;
  text-align: center !important;
  white-space: normal !important;
  transition: all .2s ease !important;
  height: 100% !important;
  cursor: pointer;
}

/* .crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
} */
.ant-upload-list {
  display: none !important;
}

.ant-tabs-tab .ant-tabs-tab-active {
  border: solid 1px !important;
}

@media (min-width: 425px) {
  .btns-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .container-qrcodeGen {
    grid-template-columns: 1fr 1fr;
    
  }
  .btn-edit {
    width: 100% !important;
  }
}
@media (min-width: 840px) {
  .btns-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .title-txt {
    font-size: 36px !important;
  }
}
@media (min-width: 1080px) {
  /* .qrcode-container {
  } */
  .container-qrcodeGen {
    grid-template-columns: 40% 34% 1fr;
  }
}
@media (min-width: 1200px) {
  .btns-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
  .container-qrcodeGen {
    grid-template-columns: 48% 30% 1fr;
  }
}
@media (min-width: 1440px) {
  .qrCodeExample {
    width: 240px;
  }
}

